import { Text, Link } from '@cash-me/react-components'

import { pushDataLayer } from 'utils/push-data-layer'

import { List, ListItem } from './styles'

const handleClickContact = (section, questionOrder) => {
  const question = questionList[section - 1].questions[questionOrder - 1]

  pushDataLayer({
    category: 'Clique',
    action: 'Botões',
    label: `Accordion Pergunta: ${question.title} - Fale Conosco`
  })

  location.href = 'http://www.cashme.com.br/fale-conosco'
}

const handleClickPrivacy = (section, questionOrder) => {
  const question = questionList[section - 1].questions[questionOrder - 1]

  pushDataLayer({
    category: 'Clique',
    action: 'Botões',
    label: `Accordion Pergunta: ${question.title} - Aviso de Privacidade`
  })

  location.href = 'http://www.cashme.com.br/aviso-de-privacidade/'
}

const handleClickHomeEquity = (section, questionOrder) => {
  const question = questionList[section - 1].questions[questionOrder - 1]

  pushDataLayer({
    category: 'Clique',
    action: 'Botões',
    label: `Accordion Pergunta: ${question.title} - Empréstimo com Garantia de Imóvel`
  })

  location.href = 'http://www.cashme.com.br/emprestimo-com-garantia-de-imovel/'
}

const handleClickWhatsApp = (section, questionOrder) => {
  const question = questionList[section - 1].questions[questionOrder - 1]

  pushDataLayer({
    category: 'Clique',
    action: 'Botões',
    label: `Accordion Pergunta: ${question.title} - WhatsApp: (11) 4040-2550`
  })
}

const handleClickMail = (section, questionOrder) => {
  const question = questionList[section - 1].questions[questionOrder - 1]

  pushDataLayer({
    category: 'Clique',
    action: 'Botões',
    label: `Accordion Pergunta: ${question.title} - E-mail: suporte.pagamento@cashme.com.br`
  })
}

export const questionList = [
  {
    id: 'section01',
    title: 'Sobre a CashMe',
    description: `Você ainda não nos conhece direito? Então, essa é a oportunidade perfeita de saber quem 
    nós somos, o que fazemos, quais são os nossos objetivos e como podemos te ajudar! Confira as perguntas 
    mais comuns feitas em nossos canais de atendimento e tire todas as suas dúvidas sobre a CashMe.`,
    questions: [
      {
        id: 'section01-01',
        title: 'Quem é a CashMe?',
        content: () => (
          <>
            <Text size="body2" color="var(--colors-neutral-text-weak)">
              A CashMe é a maior fintech de crédito com garantia imobiliária do Brasil, estabelecida em 
              2018, com o objetivo de revolucionar o segmento de Home Equity. Parte do Grupo Cyrela, uma 
              das construtoras mais respeitadas do país, com mais de 60 anos de experiência e mais de 100 
              mil imóveis vendidos, a CashMe une a tradição e solidez de sua matriz com a inovação e 
              agilidade de uma fintech.
            </Text>
            <Text size="body2" color="var(--colors-neutral-text-weak)">
              Nosso compromisso é oferecer soluções de crédito de forma ágil, segura e descomplicada. 
              Incentivamos sonhos e projetos de vida por meio de um processo 100% digital, mantendo a 
              essência do atendimento humanizado. Pensamos em cada detalhe para transformar a experiência 
              de financiamento ou consórcio em um marco de realizações e prosperidade para nossos clientes 
              e parceiros.
            </Text>
            <Text size="body2" color="var(--colors-neutral-text-weak)">
              Nossa missão é crescer juntos, proporcionando uma vivência diferenciada que combine 
              eficiência e um toque pessoal em cada interação. Com uma visão de futuro e um forte 
              compromisso com a inovação, a CashMe continua a expandir suas operações, sempre focada 
              em oferecer o melhor serviço e suporte aos seus clientes, ajudando-os a alcançar seus 
              objetivos de maneira mais rápida e transparente.
            </Text>
          </>
        )
      },
      {
        id: 'section01-02',
        title: 'Vocês atendem a todo o Brasil?',
        content: () => (
          <Text size="body2" color="var(--colors-neutral-text-weak)">
            Sim, a CashMe oferece serviços para grande parte do território brasileiro. Para verificar se o 
            seu imóvel está dentro da nossa área de atuação, informe a localização do seu imóvel durante a 
            simulação.
          </Text>
        )
      },
      {
        id: 'section01-03',
        title: 'Onde vocês estão localizados?',
        content: () => (
          <Text size="body2" color="var(--colors-neutral-text-weak)">
            Nosso escritório central fica em São Paulo (SP), no bairro Vila Olímpia, conhecido por ser 
            um polo de empresas de tecnologia e inovação.
          </Text>
        )
      },
      {
        id: 'section01-04',
        title: 'Qual é o número de telefone da CashMe?',
        content: () => (
          <Text size="body2" color="var(--colors-neutral-text-weak)">
            Atualmente, estamos nos preparando para abrir um canal direto de atendimento telefônico. 
            Enquanto isso, você pode entrar em {' '}
            <Link onClick={() => handleClickContact(1, 4)}>
              <Text as="span" size="body2" color="var(--colors-blue-text-default)">
                contato conosco
              </Text>
            </Link>
            {' '}
            através do formulário no site e esperar por uma resposta em até 24 horas úteis.
          </Text>
        )
      },
      {
        id: 'section01-05',
        title: 'Para que utilizam meus dados pessoais?',
        content: () => (
          <Text size="body2" color="var(--colors-neutral-text-weak)">
            A CashMe utiliza os seus dados pessoais com o objetivo de oferecer serviços personalizados e 
            manter a segurança das transações. As informações são tratadas com confidencialidade e você 
            pode consultar a nossa {' '} 
            <Link onClick={() => handleClickPrivacy(1, 5)}>
              <Text as="span" size="body2" color="var(--colors-blue-text-default)">
                Política de Privacidade
              </Text>
            </Link>
            {' '} 
            para mais detalhes.
          </Text>
        )
      },
      {
        id: 'section01-06',
        title: 'Como faço para evitar golpes financeiros em nome da CashMe?',
        content: () => (
          <>
            <Text size="body2" color="var(--colors-neutral-text-weak)">
              Para proteger-se contra fraudes, nunca faça depósitos ou pagamentos antecipados se alguém 
              alegar que são necessários para a aprovação de crédito em nome da CashMe. Também é importante 
              verificar o endereço do remetente de e-mails e nunca compartilhar códigos de segurança. Em 
              caso de dúvida, contate-nos diretamente pelo canal oficial.
            </Text>
          </>
        )
      }
    ]
  },
  {
    id: 'section02',
    title: 'Sobre o Empréstimo',
    description: `Se você quer saber mais sobre como funcionam as modalidades de empréstimo disponíveis 
    aqui na CashMe, você está no lugar certo. Tire todas as suas dúvidas a seguir!`,
    questions: [
      {
        id: 'section02-01',
        title: 'O que é alienação fiduciária?',
        content: () => (
          <>
            <Text size="body2" color="var(--colors-neutral-text-weak)">
              Alienação fiduciária é um acordo de garantia onde o imóvel do tomador do empréstimo fica 
              registrado em nome da instituição financeira até que a dívida seja quitada, garantindo a 
              segurança da operação. Durante esse período, o proprietário mantém o direito de uso do imóvel.
            </Text>
          </>
        )
      },
      {
        id: 'section02-02',
        title: 'Qual é a taxa de juros?',
        content: () => (
          <Text size="body2" color="var(--colors-neutral-text-weak)">
            A taxa de juros é fixa e personalizada, calculada com base no seu perfil e nas condições 
            específicas do empréstimo, buscando sempre oferecer a melhor opção para o seu bolso.
          </Text>
        )
      },
      {
        id: 'section02-03',
        title: 'Como funciona o empréstimo com garantia de imóvel?',
        content: () => (
          <>
            <Text size="body2" color="var(--colors-neutral-text-weak)">
              O {' '}
              <Link onClick={() => handleClickHomeEquity(1, 5)}>
                <Text as="span" size="body2" color="var(--colors-blue-text-default)">
                  empréstimo com garantia de imóvel
                </Text>
              </Link>
              {' '}
              é uma linha de crédito destinada tanto à pessoa física quanto à jurídica. Como o imóvel é 
              usado como garantia, as taxas de juros são bem mais baixas se comparadas às outras modalidades. 
              Além disso, o prazo de pagamento é estendido (até 240 meses) 
              e o valor também é maior (a partir de R$50 mil).
            </Text>
          </>
        )
      },
      {
        id: 'section02-04',
        title: 'O empréstimo com garantia de imóvel é para mim?',
        content: () => (
          <Text size="body2" color="var(--colors-neutral-text-weak)">
            O empréstimo com garantia de imóvel é ideal para quem, por exemplo, deseja captar recursos para 
            investimentos, organizar dívidas ou reformar um imóvel. Com a CashMe, o seu projeto pode ter 
            qualquer tamanho e você pode usar o crédito para a finalidade que escolher!
          </Text>
        )
      },
      {
        id: 'section02-05',
        title: 'Vocês aceitam terreno como garantia?',
        content: () => (
          <Text size="body2" color="var(--colors-neutral-text-weak)">
            Sim, terrenos urbanos podem ser usados como garantia, desde que estejam de acordo com os 
            critérios estabelecidos mediante análise.
          </Text>
        )
      },
      {
        id: 'section02-06',
        title: 'Qual é o valor máximo de empréstimo que posso solicitar?',
        content: () => (
          <Text size="body2" color="var(--colors-neutral-text-weak)">
            O valor máximo do empréstimo é de até 60% do valor de avaliação do imóvel, Na CashMe, o 
            limite máximo é de R$25 milhões.
          </Text>
        )
      },
      {
        id: 'section02-07',
        title: 'Qual é o valor mínimo de empréstimo que posso solicitar?',
        content: () => (
          <Text size="body2" color="var(--colors-neutral-text-weak)">
            O valor mínimo para empréstimos é de R$50 mil.
          </Text>
        )
      },
      {
        id: 'section02-08',
        title: 'Vocês aceitam carro ou outro como garantia?',
        content: () => (
          <Text size="body2" color="var(--colors-neutral-text-weak)">
            Não, atualmente a CashMe não aceita veículos ou outros bens como garantia, focando 
            exclusivamente em imóveis urbanos.
          </Text>
        )
      },
      {
        id: 'section02-09',
        title: 'Consigo solicitar um empréstimo em nome de Pessoa Jurídica ou com garantia em nome de Pessoa Jurídica?',
        content: () => (
          <Text size="body2" color="var(--colors-neutral-text-weak)">
            Sim, é possível obter um empréstimo tanto em nome de Pessoa Jurídica quanto utilizando um bem 
            registrado em nome de uma Pessoa Jurídica como garantia.
          </Text>
        )
      },
      {
        id: 'section02-10',
        title: 'Consigo solicitar um empréstimo estando negativado?',
        content: () => (
          <Text size="body2" color="var(--colors-neutral-text-weak)">
            Sim. Na CashMe, você pode solicitar um empréstimo mesmo com restrições de crédito. 
            Entretanto, todos os perfis passam por uma análise para viabilizar a operação.
          </Text>
        )
      }
    ]
  },
  {
    id: 'section03',
    title: 'Imóvel como Garantia',
    description: `Agora é hora de tirar as suas principais dúvidas a respeito do uso de imóveis como 
    garantia aqui na CashMe. Confira as respostas a seguir:`,
    questions: [
      {
        id: 'section03-01',
        title: 'Como o imóvel é colocado em garantia?',
        content: () => (
          <Text size="body2" color="var(--colors-neutral-text-weak)">
            Quando você utiliza um imóvel como garantia, ele é registrado em favor da instituição 
            financeira durante o prazo do financiamento. Apesar disso, você continua usufruindo do imóvel.
          </Text>
        )
      },
      {
        id: 'section03-02',
        title: 'Posso deixar um imóvel financiado como garantia?',
        content: () => (
          <Text size="body2" color="var(--colors-neutral-text-weak)">
            Sim. No caso, o valor que você solicitará como empréstimo, somado ao saldo devedor de seu 
            financiamento, não poderá ultrapassar 60% do valor de avaliação do seu imóvel.
          </Text>
        )
      },
      {
        id: 'section03-03',
        title: 'Meu imóvel não tem inventário feito. Posso fazer empréstimo?',
        content: () => (
          <Text size="body2" color="var(--colors-neutral-text-weak)">
            Infelizmente não. A documentação do imóvel deve estar regularizada para iniciarmos a sua análise.
          </Text>
        )
      },
      {
        id: 'section03-04',
        title: 'Tenho dívidas de IPTU. Consigo fazer o empréstimo?',
        content: () => (
          <Text size="body2" color="var(--colors-neutral-text-weak)">
            Neste caso, realizamos uma análise de crédito e, se tivermos a aprovação, condicionamos a 
            quitação do IPTU por dentro da operação. É importante apenas que você nos informe o valor 
            da dívida, para que possamos considerá-la em sua análise.
          </Text>
        )
      },
      {
        id: 'section03-05',
        title: 'Tenho dívidas de condomínio. Consigo fazer empréstimo?',
        content: () => (
          <Text size="body2" color="var(--colors-neutral-text-weak)">
            Neste processo, realizamos uma análise de crédito e, caso seja aprovada, incluímos a quitação 
            do condomínio como parte da operação. É fundamental que você nos informe o valor da dívida, 
            para que possamos considerá-la em nossa avaliação.
          </Text>
        )
      }
    ]
  },
  {
    id: 'section04',
    title: 'Processo de contração',
    description: `Quer saber mais sobre como funciona o nosso processo de contratação? Então, continue a 
    leitura e veja as respostas para as dúvidas mais comuns!`,
    questions: [
      {
        id: 'section04-01',
        title: 'Em quanto tempo o dinheiro é depositado na conta?',
        content: () => (
          <Text size="body2" color="var(--colors-neutral-text-weak)">
            Após a aprovação do crédito, é feito o registro do contrato, o que pode levar em torno de 7 
            dias úteis para que o montante seja liberado na conta indicada.
          </Text>
        )
      },
      {
        id: 'section04-02',
        title: 'Como é feito o pagamento do empréstimo?',
        content: () => (
          <Text size="body2" color="var(--colors-neutral-text-weak)">
            O pagamento pode ser feito por meio de boletos ou débito direto autorizado (DDA), que é um 
            sistema eletrônico para pagamentos.
          </Text>
        )
      },
      {
        id: 'section04-03',
        title: 'De quais documentos vocês precisam?',
        content: () => (
          <Text size="body2" color="var(--colors-neutral-text-weak)">
            Para a análise de crédito, é fundamental apresentar o IPTU atualizado, com todos os detalhes 
            do imóvel, incluindo matrícula e medidas.
          </Text>
        )
      },
      {
        id: 'section04-04',
        title: 'Com quantas pessoas posso fazer composição de renda?',
        content: () => (
          <Text size="body2" color="var(--colors-neutral-text-weak)">
            A renda pode ser composta por até quatro pessoas, o que pode ajudar na aprovação do crédito 
            ao demonstrar maior capacidade de pagamento.
          </Text>
        )
      },
      {
        id: 'section04-05',
        title: 'Quanto vocês cobram pelo serviço?',
        content: () => (
          <Text size="body2" color="var(--colors-neutral-text-weak)">
            Não é cobrada uma taxa adicional pelo serviço. As tarifas são incluídas nas parcelas do 
            empréstimo e detalhadas no contrato assinado.
          </Text>
        )
      }
    ]
  },
  {
    id: 'section05',
    title: 'Sobre Pagamento',
    description: `Por fim, chegou o momento de tirar todas as suas dúvidas sobre o pagamento do seu 
    empréstimo. Confira a seguir as nossas respostas!`,
    questions: [
      {
        id: 'section05-01',
        title: 'Como faço para saber o valor da parcela?',
        content: () => (
          <>
            <Text size="body2" color="var(--colors-neutral-text-weak)">
              Para obter informações detalhadas sobre o valor da sua parcela, você pode acessar nosso 
              suporte através dos seguintes canais:
            </Text>
            <List>
              <ListItem>
                WhatsApp: {' '}
                <Link
                  href='https://api.whatsapp.com/send/?phone=551140402550' 
                  target='_blank'
                  onClick={() => handleClickWhatsApp(5, 1)}
                >
                  <Text as="span" size="body2" color="var(--colors-blue-text-default)">
                    (11) 4040-2550;
                  </Text>
                </Link>
              </ListItem>
              <ListItem>
                E-mail: {' '}
                <Link
                  href='mailto:suporte.pagamento@cashme.com.br' 
                  onClick={() => handleClickMail(5, 1)}
                >
                  <Text as="span" size="body2" color="var(--colors-blue-text-default)">
                    suporte.pagamento@cashme.com.br.
                  </Text>
                </Link>
              </ListItem>
            </List>
            <Text size="body2" color="var(--colors-neutral-text-weak)">
              Nossa equipe está disponível de segunda a sexta-feira, das 9h às 17h, para fornecer todos os 
              detalhes do seu contrato, como o valor de cada parcela e o cronograma de pagamentos. 
              Lembre-se de ter em mãos o número do contrato ou CPF para agilizar o atendimento.
            </Text>
          </>
        )
      },
      {
        id: 'section05-02',
        title: 'Posso antecipar as parcelas?',
        content: () => (
          <>
            <Text size="body2" color="var(--colors-neutral-text-weak)">
              Sim, é possível antecipar suas parcelas. Para isso, basta entrar em contato conosco pelos 
              canais de suporte listados:
            </Text>
            <List>
              <ListItem>
                WhatsApp: {' '}
                <Link
                  href='https://api.whatsapp.com/send/?phone=551140402550' 
                  target='_blank'
                  onClick={() => handleClickWhatsApp(5, 1)}
                >
                  <Text as="span" size="body2" color="var(--colors-blue-text-default)">
                    (11) 4040-2550;
                  </Text>
                </Link>
              </ListItem>
              <ListItem>
                E-mail: {' '}
                <Link
                  href='mailto:suporte.pagamento@cashme.com.br' 
                  onClick={() => handleClickMail(5, 1)}
                >
                  <Text as="span" size="body2" color="var(--colors-blue-text-default)">
                    suporte.pagamento@cashme.com.br.
                  </Text>
                </Link>
              </ListItem>
            </List>
            <Text size="body2" color="var(--colors-neutral-text-weak)">
              Nosso atendimento está à disposição de segunda a sexta-feira, das 9h às 17h, para explicar 
              como você pode realizar a antecipação.
            </Text>
          </>
        )
      },
      {
        id: 'section05-03',
        title: 'Posso pagar com o FGTS?',
        content: () => (
          <Text size="body2" color="var(--colors-neutral-text-weak)">
            O Fundo de Garantia do Tempo de Serviço (FGTS) é uma opção disponível para a aquisição de 
            bens como imóveis, mas não pode ser utilizado diretamente para a quitação ou pagamento de 
            parcelas do empréstimo com garantia de imóvel na CashMe. Contudo, caso você tenha direito ao 
            saque do FGTS por outro motivo, poderá utilizar o valor retirado para esse fim de maneira 
            indireta.
          </Text>
        )
      },
      {
        id: 'section05-04',
        title: 'Como funciona a carência adicional na CashMe?',
        content: () => (
          <>
            <Text size="body2" color="var(--colors-neutral-text-weak)">
              Na CashMe, você tem a possibilidade de solicitar um período de carência adicional. Durante 
              este período, você não precisará efetuar o pagamento das parcelas, o que pode dar um fôlego 
              para reorganizar suas finanças.
            </Text>
            <Text size="body2" color="var(--colors-neutral-text-weak)">
              Para solicitar, basta entrar em contato com nosso suporte. Caso seja aprovado após a análise 
              interna, que verifica todas as obrigações contratuais, as parcelas serão adiadas.
            </Text>
          </>
        )
      }
    ]
  }
]
