import { forwardRef, memo } from 'react'

import { GridProps } from './Grid.model'
import * as S from './Grid.styles'

const initialCol = { phone: 4, tablet: 12, desktop: 12 }
const initialGap = { phone: 'sm', tablet: 'sm', desktop: 'md' }

export const Grid = memo(
  forwardRef<HTMLDivElement, GridProps>(({ children, col = initialCol, gap = initialGap }, ref) => {
    const newCols = { ...initialCol, ...col }
    const newGaps = { ...initialGap, ...gap }

    return (
      <S.Grid $col={newCols} $gap={newGaps} ref={ref}>
        {children}
      </S.Grid>
    )
  })
)

Grid.displayName = 'Grid'
