import { useCallback } from 'react'

import { Button, Divider, Icon, Text } from '@cash-me/react-components'
import NextLink from 'next/link'
import { useRouter } from 'next/router'

import { Popper } from 'components/popper'
import { pushDataLayer } from 'utils/push-data-layer'

import * as S from './styles'

export const Topbar = () => {
  const router = useRouter()

  const handleDataLayer = useCallback((section: string, name: string) => {
    pushDataLayer({
      category: 'Clique',
      action: 'Header',
      label: `${section} - Link ${name}`
    })
  }, [])

  return (
    <S.Wrapper>
      <Popper>
        <S.Trigger>
          <Text size="body2" weight="bold">
            Para você
          </Text>
          <Icon name="chevronDown" color="var(--colors-blue-icon-default)" size={24} />
        </S.Trigger>
        <S.Dropdown>
          <li>
            <NextLink
              href="/emprestimo-com-garantia-de-imovel"
              onClick={() => {
                handleDataLayer('Para você', 'Empréstimo com Garantia de Imóvel')
              }}
              className="ds-link"
            >
              Empréstimo com garantia de imóvel
            </NextLink>
          </li>
          <Divider />
          <li>
            <NextLink
              href="/consorcio/"
              onClick={() => {
                handleDataLayer('Para você', 'Consórcio')
              }}
              className="ds-link"
            >
              Consórcio
            </NextLink>
          </li>
          <Divider />
          <li>
            <NextLink
              href="/consorcio-de-carros/"
              onClick={() => {
                handleDataLayer('Para você', 'Consórcio de Veículos')
              }}
              className="ds-link"
            >
              Consórcio de Veículos
            </NextLink>
          </li>
          <Divider />
          <li>
            <NextLink
              href="/consorcio-de-imoveis/"
              onClick={() => {
                handleDataLayer('Para você', 'Consórcio de Imóveis')
              }}
              className="ds-link"
            >
              Consórcio de Imóveis
            </NextLink>
          </li>
          <Divider />
          <li>
            <NextLink
              href="/financiamento-energia-solar"
              onClick={() => {
                handleDataLayer('Para você', 'Financiamento para Energia Solar')
              }}
              className="ds-link"
            >
              Financiamento para Energia Solar
            </NextLink>
          </li>
          <Divider />
          <li>
            <NextLink
              href="/financiamento-imobiliario"
              onClick={() => {
                handleDataLayer('Para você', 'Financiamento Imobiliário')
              }}
              className="ds-link"
            >
              Financiamento Imobiliário (SFI)
            </NextLink>
          </li>
          <Divider />
          <li>
            <NextLink
              href="/financiamento-para-construcao"
              onClick={() => {
                handleDataLayer('Para você', 'Financiamento para Construção')
              }}
              className="ds-link"
            >
              Financiamento para Construção
            </NextLink>
          </li>
        </S.Dropdown>
      </Popper>
      <Popper>
        <S.Trigger>
          <Text size="body2" weight="bold">
            Para seu negócio
          </Text>
          <Icon name="chevronDown" color="var(--colors-blue-icon-default)" size={24} />
        </S.Trigger>
        <S.Dropdown>
          <li>
            <NextLink
              href="/emprestimo-com-garantia-de-imovel"
              onClick={() => {
                handleDataLayer('Para seu negócio', 'Empréstimo com Garantia de Imóvel')
              }}
              className="ds-link"
            >
              Empréstimo com garantia de imóvel
            </NextLink>
          </li>
          <Divider />
          <li>
            <NextLink
              href="/consorcio/"
              onClick={() => {
                handleDataLayer('Para seu negócio', 'Consórcio')
              }}
              className="ds-link"
            >
              Consórcio
            </NextLink>
          </li>
          <Divider />
          <li>
            <NextLink
              href="/consorcio-de-carros/"
              onClick={() => {
                handleDataLayer('Para você', 'Consórcio de Veículos')
              }}
              className="ds-link"
            >
              Consórcio de Veículos
            </NextLink>
          </li>
          <Divider />
          <li>
            <NextLink
              href="/consorcio-de-imoveis/"
              onClick={() => {
                handleDataLayer('Para você', 'Consórcio de Imóveis')
              }}
              className="ds-link"
            >
              Consórcio de Imóveis
            </NextLink>
          </li>
          <Divider />
          <li>
            <NextLink
              href="/financiamento-para-construcao"
              onClick={() => {
                handleDataLayer('Para você', 'Financiamento Imobiliário')
              }}
              className="ds-link"
            >
              Financiamento para Construção
            </NextLink>
          </li>
          <Divider />
          <li>
            <NextLink
              href="/financiamento-energia-solar"
              onClick={() => {
                handleDataLayer('Para seu negócio', 'Financiamento para Energia Solar')
              }}
              className="ds-link"
            >
              Financiamento para Energia Solar
            </NextLink>
          </li>
        </S.Dropdown>
      </Popper>
      <Popper>
        <S.Trigger>
          <Text size="body2" weight="bold">
            Para seu condomínio
          </Text>
          <Icon name="chevronDown" color="var(--colors-blue-icon-default)" size={24} />
        </S.Trigger>
        <S.Dropdown>
          <li>
            <NextLink
              href="/condo"
              onClick={() => {
                handleDataLayer('Para seu condomínio', 'Empréstimo para condomínio')
              }}
              className="ds-link"
            >
              Empréstimo para condomínio
            </NextLink>
          </li>
          <Divider />
          <li>
            <NextLink
              href="/financiamento-energia-solar"
              onClick={() => {
                handleDataLayer('Para seu condomínio', 'Financiamento para Energia Solar')
              }}
              className="ds-link"
            >
              Financiamento para Energia Solar
            </NextLink>
          </li>
        </S.Dropdown>
      </Popper>
      <Popper>
        <S.Trigger>
          <Text size="body2" weight="bold">
            A CashMe
          </Text>
          <Icon name="chevronDown" color="var(--colors-blue-icon-default)" size={24} />
        </S.Trigger>
        <S.Dropdown>
          <li>
            <NextLink
              href="/quem-somos"
              onClick={() => {
                handleDataLayer('A CashMe', 'Sobre a CashMe')
              }}
              className="ds-link"
            >
              Sobre a CashMe
            </NextLink>
          </li>
          <Divider />
          <li>
            <NextLink
              href="/fale-conosco"
              onClick={() => {
                handleDataLayer('A CashMe', 'Fale Conosco')
              }}
              className="ds-link"
            >
              Fale Conosco
            </NextLink>
          </li>
          <Divider />
        <li>
            <NextLink
              href="https://www.cashme.com.br/blog/"
              onClick={() => {
                handleDataLayer('A CashMe', 'Blog')
              }}
              className="ds-link"
            >
              Blog CashMe
            </NextLink>
          </li>
          <Divider />
          <li>
            <NextLink
              href="/seguranca"
              onClick={() => {
                handleDataLayer('A CashMe', 'Dicas de Segurança')
              }}
              className="ds-link"
            >
              Dicas de Segurança
            </NextLink>
          </li>
          <Divider />
          <li>
            <NextLink
              href="https://www.cashme.com.br/construcast/"
              onClick={() => {
                handleDataLayer('A CashMe', 'Construcast')
              }}
              className="ds-link"
            >
              ConstruCast
            </NextLink>
          </li>
          <Divider />
          <li>
            <NextLink
              href="/programa-de-integridade"
              onClick={() => {
                handleDataLayer('A CashMe', 'Programa de Integridade')
              }}
              className="ds-link"
            >
              Programa de Integridade
            </NextLink>
          </li>
        </S.Dropdown>
      </Popper>
      <NextLink
        href="/seja-um-parceiro"
        onClick={() => {
          handleDataLayer('Seja parceiro', 'Seja parceiro')
        }}
        className="ds-link"
      >
        <Text size="body2" weight="bold" color="var(--colors-neutral-text-default)">
          Seja parceiro
        </Text>
      </NextLink>
      <Divider type="vertical" />
      <Button
        size="sm"
        color="primary"
        variants="text-only"
        onClick={() => {
          handleDataLayer('Sou cliente', 'Sou cliente')
          location.href = '/sou-cliente'
        }}
      >
        Sou cliente
      </Button>
      <Button
        size="sm"
        color="secondary"
        variants="text-only"
        onClick={() => {
          handleDataLayer('Sou parceiro', 'Sou parceiro')
          const { asPath } = router
          const url = asPath.includes('/condo/')
            ? process.env.NEXT_PUBLIC_CONDO_URL
            : process.env.NEXT_PUBLIC_CASHMEMBER_URL

          window.open(url)
        }}
      >
        Sou parceiro
      </Button>
    </S.Wrapper>
  )
}
