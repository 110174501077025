import styled from 'styled-components'

import { theme } from 'styles/theme'

interface ColProps {
  readonly $col: { phone: string | number; tablet: string | number; desktop: string | number }
  readonly start: { phone: string | number; tablet: string | number; desktop: string | number }
}

const { media } = theme

export const Col = styled.div<ColProps>`
  box-sizing: border-box;
  width: 100%;
  grid-column-end: ${({ $col }) => `span ${$col.phone}`};
  grid-column-start: ${({ start }) => start.phone};

  @media (${media.tablet}) {
    grid-column-end: ${({ $col }) => `span ${$col.tablet}`};
    grid-column-start: ${({ start }) => start.tablet};
  }

  @media (${media.desktop}) {
    grid-column-end: ${({ $col }) => `span ${$col.desktop}`};
    grid-column-start: ${({ start }) => start.desktop};
  }
`
