import { forwardRef, memo } from 'react'

import * as S from './Col.styles'
import { ColProps } from './Grid.model'

const initialCols = { phone: 1, tablet: 1, desktop: 1 }
const initialStarts = { phone: 'auto', tablet: 'auto', desktop: 'auto' }

export const Col = memo(
  forwardRef<HTMLDivElement, ColProps>(({ children, col = initialCols, start = initialStarts }, ref) => {
    const newCols = { ...initialCols, ...col }
    const newStarts = { ...initialStarts, ...start }

    return (
      <S.Col $col={newCols} start={newStarts} ref={ref}>
        {children}
      </S.Col>
    )
  })
)

Col.displayName = 'Col'
